export default (error, dictionary) => {
  const statusDefault = 500
  const messageDefault =
    'Não se preocupe, seus dados não foram afetados.\n Estamos trabalhando para evitar que se repita.'

  if (error && error.response && error.response.data) {
    if (error.response.data.errorMessage) {
      return {
        status: error.response.status || statusDefault,
        message: dictionary[error.response.data.errorMessage] || messageDefault,
        error: error.response.data
      }
    }

    if (error.response.data.message) {
      return {
        status:
          error.response.data.status_code ||
          error.response.data.status ||
          error.response.data.statusCode ||
          statusDefault,
        message: dictionary[error.response.data.message] || messageDefault,
        error: error.response.data
      }
    }

    if (error.response.data.reason) {
      return {
        status: error.response.status || statusDefault,
        message: error.response.data.reason || messageDefault,
        error: error.response.data
      }
    }

    if (error.response.data.detail) {
      return {
        status: error.response.data.status || statusDefault,
        message: dictionary[error.response.data.detail] || messageDefault,
        error: error.response.data
      }
    }

    if (error.response.data.error) {
      if (
        error.response.data.error.message &&
        dictionary[error.response.data.error.message]
      ) {
        return {
          status: error.response.data.error.status_code || statusDefault,
          message: dictionary[error.response.data.error.message],
          error: error.response.data
        }
      }

      return {
        status: error.response.data.error.status_code || statusDefault,
        message:
          dictionary[
            error.response.data.category || error.response.data.error
          ] || messageDefault,
        error: error.response.data
      }
    }

    return {
      status: error.response.data.status_code || statusDefault,
      message: dictionary[error.response.data.category] || messageDefault,
      error: error.response.data
    }
  }

  return {
    status: statusDefault,
    message: messageDefault,
    error
  }
}
